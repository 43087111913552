<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-14 09:22:22
 * @LastEditTime: 2021-05-11 10:20:30
 * @LastEditors: yangliao
 * @Description: 抄表记录
 * @FilePath: /netHallOfficialAccounts/src/views/SelfMeterRead/meterReadingRecord.vue
-->
<template>
  <div class="record-page">
    <div v-if="dataInfoFlag">
      <div class="record-topContent">
        <div class="topContentLi">
          <em>户号</em>
          <p>{{ meterData.clientCode }}</p>
        </div>
        <div class="topContentLi van-multi-ellipsis--l3">
          <em>水表编号</em>
          <p class="van-multi-ellipsis--l3">{{ meterData.meterCode }}</p>
        </div>
        <div class="topContentLi van-multi-ellipsis--l3">
          <em>地址</em>
          <p class="van-multi-ellipsis--l3">{{ meterData.meterAddress }}</p>
        </div>

        <!-- <p class="van-multi-ellipsis--l3">{{dataInfo.address2}}</p> -->
      </div>

      <div class="record-Btncontent">
        <van-list
          v-model="loading"
          finished-text="没有更多了"
          @load="readRecordList"
          :finished="finished"
          :immediate-check="false"
        >
          <div v-for="(item, index) in recordList" :key="index">
            <div class="record-item">
              <div class="left-info">
                <div class="img">
                  <img :src="item.imgUrl" alt="" @click="getImagePreview(item.imgUrl)" />
                </div>
                <div class="txt">
                  <span
                    >本次读数 <i>{{ item.currentRead }}</i></span
                  >
                  <p>{{ item.endTime }}</p>
                </div>
              </div>
              <div class="right-info">
                <div :style="{ color: statusMap[item.noticeBusiStatus].color }">
                  {{ statusMap[item.noticeBusiStatus].name }}
                </div>

                <p v-if="item.thirdBillState && item.thirdBillState !== '00'">¥{{ item.thirdBillAmount }}</p>
                <van-button
                  type="info"
                  size="small"
                  class="payBtn"
                  v-if="item.thirdBillState === '01'"
                  @click="gotoPage(item.thirdBillId)"
                  >立即缴费</van-button
                >
                <van-button plain type="info" size="small" class="payBtn" v-if="item.thirdBillState === '02'"
                  >账单详情</van-button
                >
              </div>
            </div>
            <div class="item-remark">
              <span v-if="item.examineRemarks">审批备注：{{ item.examineRemarks }}</span>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <no-data v-else />
  </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import img from '@/assets/img.png';
import NoData from '@/components/noData';

import { readRecordList } from '@/api/home';
import { findReadRecord } from '@/api/business';
import serverconfig from '@/api/server.config';

import { getPictureUrl } from '@/api/components';
const statusMap = {
  '00': { name: '审核中', color: 'orange' },
  '01': { name: '审核通过', color: 'green' },
  '02': { name: '审核不同意', color: 'red' }
};

export default {
  name: 'meterReadingRecord',
  components: {
    NoData
  },
  data() {
    return {
      dataInfo: {},
      recordList: [],
      dataInfoFlag: true,
      userNo: this.$route.query.userNo,
      meterData: {},
      loading: false,
      finished: false,
      total: 1,
      page: { currentPage: 1, pageSize: 4 },
      statusMap
    };
  },
  mounted() {
    this.meterData = JSON.parse(this.$route.query.info);
    this.readRecordList();
  },
  methods: {
    async readRecordList() {
      this.loading = true;
      let userNo = this.userNo;
      const { status, resultData } = await findReadRecord({ meterCode: this.meterData.meterCode, ...this.page });

      this.loading = false;
      if (status === 'complete') {
        this.dataInfo = resultData;
        this.recordList =
          this.page.currentPage === 1 ? resultData.records : [...this.recordList, ...resultData.records];
        this.total = resultData.total;
        if (this.recordList.length < this.total) {
          this.finished = false;
        } else {
          this.finished = true;
        }
        this.page.currentPage += 1;
        await this.getImgUrl();
        this.dataInfoFlag = true;
      }
    },

    // 立即缴费
    gotoPage(billId, wuserNo) {
      this.$router.push({
        name: 'FeeDetails',
        path: '/QueryPay/FeeDetails',
        query: {
          billId,
          wuserNo: this.dataInfo.userNo
        }
      });
    },
    // 图片预览
    getImagePreview(value) {
      ImagePreview({
        images: [value]
      });
    },
    async getImgUrl() {
      let imageIds = this.recordList.map((item) => item.currentMeterImage);
      if (imageIds?.length) {
        let { resultData } = await getPictureUrl(imageIds);
        resultData.forEach((file) => {
          let index = this.recordList.findIndex((item) => item.currentMeterImage === file.pictureId);
          let recordItem = this.recordList[index];
          recordItem.imgUrl = file.pictureUrl;
          this.recordList.splice(index, 1, recordItem);
        });
      } else {
        Toast('用户暂无抄表记录');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.record-page {
  .record-topContent {
    margin: 16px;
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
    div.topContentLi {
      padding-top: 16px;
      em {
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      p {
        margin: 0px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: right;
        line-height: 22px;
        // display: inline-block;
        float: right;
      }
    }
    .topContentLi:first-child {
      position: relative;
      padding: 0px;
      padding-bottom: 16px;
    }
    .topContentLi::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0px;
      bottom: 0;
      left: 0px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    p {
      margin: 0px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      text-align: right;
      line-height: 22px;
      margin-left: 35px;
    }
  }
  .record-Btncontent {
    margin: 16px;

    .record-item {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      padding: 12px 16px;

      border-radius: 8px;
      .left-info {
        display: flex;
        justify-content: space-between;
        .img {
          width: 64px;
          height: 64px;
          display: inline-block;
          img {
            width: 64px;
            height: 64px;
            border-radius: 8px;
          }
        }
        .txt {
          display: inline-block;
          padding-left: 12px;
          padding-top: 2px;
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            i {
              font-style: normal;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 20px;
            }
          }
          p {
            margin: 0px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            padding-top: 16px;
          }
        }
      }
      .right-info {
        p {
          margin: 0px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          text-align: right;
          padding-top: 2px;
        }
        .payBtn {
          margin-top: 13px;
          border-radius: 4px;
        }
      }
    }
    .item-remark {
      font-size: 12px;
      padding: 4px 16px 4px 16px;
      margin-bottom: 10px;
      background-color: #fff;
    }
  }
}
</style>
